import { gql } from '@apollo/client';

export const ACCEPT_DESIGN = gql`
  mutation AcceptMerchDesign($input: AcceptMerchDesignInput!) {
    acceptMerchDesign(input: $input) {
      id
      status
      type
    }
  }
`;

export const STORE_REJECT_MERCH_DESIGN = gql`
  mutation StoreRejectMerchDesign($input: StoreRejectMerchDesignInput!) {
    storeRejectMerchDesign(input: $input) {
      id
      status
      type
    }
  }
`;

export const CREATE_MERCH_DESIGN = gql`
  mutation CreateMerchDesign($input: CreateMerchDesignInput!) {
    createMerchDesign(input: $input) {
      id
      status
      type
    }
  }
`;

export const EDIT_MERCH_DESIGN = gql`
  mutation EditMerchDesign($input: EditMerchDesignInput!) {
    editMerchDesign(input: $input) {
      id
      status
      type
    }
  }
`;

export const CREATE_PRESIGNED_URL_FOR_EXAMPLE_IMAGES = gql`
  mutation CreatePresignedUrlForExampleImages(
    $input: CreateDesignImageExamplePresignedUrlInput!
  ) {
    createPresignedUrlForExampleImages(input: $input) {
      fields
      key
      url
    }
  }
`;

export const CREATE_MERCH_DESIGN_WITH_LOGO = gql`
  mutation CreateMerchDesignWithLogo($input: CreateMerchDesignWithLogoInput!) {
    createMerchDesignWithLogo(input: $input) {
      id
      status
      type
    }
  }
`;

export const EDIT_MERCH_DESIGN_WITH_LOGO = gql`
  mutation EditMerchDesignWithLogo($input: EditMerchDesignWithLogoInput!) {
    editMerchDesignWithLogo(input: $input) {
      id
      status
      type
    }
  }
`;
