import { gql } from '@apollo/client';
// Fragments
import {
  SocialSignUpResponseFields,
  UserBaseFields,
  UserFields,
} from './fragments';

export const SIGN_IN_V3 = gql`
  mutation SignInV3($input: SignInInput!) {
    signInV3(input: $input) {
      userId
      tokens {
        accessToken
        refreshToken
      }
      user {
        ...UserBaseFields
      }
    }
  }
  ${UserBaseFields}
`;

export const SIGN_UP_GUEST_V3 = gql`
  mutation SignUpGuestV3($input: SignUpGuestV2Input!) {
    signUpGuestV3(input: $input) {
      userId
      tokens {
        accessToken
        refreshToken
      }
    }
  }
`;

export const SIGN_UP_BASIC = gql`
  mutation SignUpBasic($input: SignUpV2Input!) {
    signUpBasic(input: $input) {
      userId
      tokens {
        accessToken
        refreshToken
      }
      user {
        id
        firstName
        lastName
        email
        role
      }
    }
  }
`;

export const GOOGLE_AUTH = gql`
  mutation GoogleAuth($input: GoogleAuthInput!) {
    googleAuth(input: $input) {
      ... on AuthV2SignInResponse {
        userId
        tokens {
          accessToken
          refreshToken
        }
        user {
          ...UserBaseFields
        }
      }
      ... on SocialSignUpResponse {
        ...SocialSignUpResponseFields
      }
    }
  }
  ${UserBaseFields}
  ${SocialSignUpResponseFields}
`;

export const FB_AUTH = gql`
  mutation FbAuth($input: FbAuthInput!) {
    fbAuth(input: $input) {
      ... on AuthV2SignInResponse {
        userId
        tokens {
          accessToken
          refreshToken
        }
        user {
          ...UserBaseFields
        }
      }
      ... on SocialSignUpResponse {
        ...SocialSignUpResponseFields
      }
    }
  }
  ${UserBaseFields}
  ${SocialSignUpResponseFields}
`;

export const TWITTER_AUTH = gql`
  mutation TwitterAuth($input: TwitterAuthInput!) {
    twitterAuth(input: $input) {
      ... on AuthV2SignInResponse {
        userId
        tokens {
          accessToken
          refreshToken
        }
        user {
          ...UserBaseFields
        }
      }
      ... on SocialSignUpResponse {
        ...SocialSignUpResponseFields
      }
    }
  }
  ${UserBaseFields}
  ${SocialSignUpResponseFields}
`;

export const TIKTOK_V2_AUTH = gql`
  mutation TiktokV2Auth($input: TikTokV2AuthInput!) {
    tiktokV2Auth(input: $input) {
      ... on AuthV2SignInResponse {
        userId
        tokens {
          accessToken
          refreshToken
        }
        user {
          ...UserBaseFields
        }
      }
      ... on SocialSignUpResponse {
        ...SocialSignUpResponseFields
      }
    }
  }
  ${UserBaseFields}
  ${SocialSignUpResponseFields}
`;

export const APPLE_AUTH = gql`
  mutation AppleAuth($input: AppleAuthInput!) {
    appleAuth(input: $input) {
      ... on AuthV2SignInResponse {
        userId
        tokens {
          accessToken
          refreshToken
        }
        user {
          ...UserBaseFields
        }
      }
      ... on SocialSignUpResponse {
        ...SocialSignUpResponseFields
      }
    }
  }
  ${UserBaseFields}
  ${SocialSignUpResponseFields}
`;

export const SWITCH_TO_CREATOR = gql`
  mutation SwitchToCreator($input: SwitchToCreatorInput!) {
    switchToCreator(input: $input) {
      ...UserFields
    }
  }
  ${UserFields}
`;

/** The mutation and its types are used at helpers/auth.ts helper */
export const REFRESH_TOKENS = gql`
  mutation RefreshTokens($input: RefreshTokensInput!) {
    refreshTokens(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const SIGN_OUT = gql`
  mutation SignOut($input: SignOutInput!) {
    singOut(input: $input)
  }
`;

export const SAVE_EMAIL = gql`
  mutation SaveEmail($email: String!) {
    saveEmail(email: $email)
  }
`;

export const EDIT_USER = gql`
  mutation EditUser($input: UserUpdateInput!) {
    editUser(input: $input) {
      id
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      id
      hasPassword
    }
  }
`;

export const SEND_PASSWORD_RESET_TOKEN = gql`
  mutation SendPasswordResetToken($email: String!) {
    sendPasswordResetToken(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: PasswordResetInput!) {
    resetPassword(passwordResetInput: $input)
  }
`;

export const INIT_TWITTER_AUTH = gql`
  mutation InitTwitterAuth($input: InitAuthTwitterInput!) {
    initTwitterAuth(input: $input) {
      authUrl
    }
  }
`;

export const INIT_TIKTOK_AUTH_V2 = gql`
  mutation InitTiktokAuthV2($input: InitTiktokAuthInput!) {
    initTiktokAuthV2(input: $input) {
      authUrl
    }
  }
`;
