import cn from 'classnames';
// Styles
import styles from './SplitLeftRightView.module.scss';

type SplitLeftRightViewProps = {
  left: React.ReactNode;
  right: React.ReactNode;
  hideLeftOnMobile?: boolean;
};

const SplitLeftRightView = ({
  left,
  right,
  hideLeftOnMobile = false,
}: SplitLeftRightViewProps) => {
  return (
    <div className={styles.root}>
      <div
        className={cn(styles.leftPart, {
          [styles.hideLeftOnMobile]: hideLeftOnMobile,
        })}
      >
        {left}
      </div>
      <div className={styles.rightPart}>{right}</div>
    </div>
  );
};

export default SplitLeftRightView;
