import { gql } from '@apollo/client';
// Fragments
import { UserFields, upcomingParticipatedStreamFields } from './fragments';

export const SUGGEST_USERNAMES = gql`
  query SuggestUsernames($input: SuggestUsernameInput!) {
    suggestUsernames(input: $input) {
      suggestions
    }
  }
`;

export const CHECK_USERNAME_AVAILABILITY = gql`
  query CheckUsernameAvailability($input: CheckUsernameAvailabilityInput!) {
    checkUsernameAvailability(input: $input) {
      isAvailable
    }
  }
`;

export const ME = gql`
  query Me {
    me {
      ...UserFields
      ...upcomingParticipatedStreamFields
    }
  }
  ${UserFields}
  ${upcomingParticipatedStreamFields}
`;

export const ME_PROFILE_SETUP = gql`
  query MeProfileSetup {
    me {
      id
      role
      lastName
      firstName
      slug
      email
      phoneNumber
      isOnboarded
      isEmailSet
      onboardingSteps
      status
      hasPassword
      phoneOptIn
      avatarKey
      avatarUrl
      brandDealSettings {
        id
        contactEmail
        contactPhone
        minFlatPrice
        industryCategories {
          isEnabled
          label
          value
        }
        isAcceptingFreeProducts
        isArticlesPrEnabled
        minBookMePrice
        minSocialPrice
        minSponsoredLivePrice
      }
      sports {
        id
        name
      }
      hashtags {
        id
        name
      }
      storeDetails {
        id
        athleticLevel
        storeName
        storeTitle
        bio
        avatarURL
        socialMediaLink
        description
        coverURL
        facebookLink
        twitterLink
        instagramLink
        tiktokLink
        youtubeLink
        birthDate
        gender
        instagramFollowing
        facebookFollowing
        twitterFollowing
        tiktokFollowing
        youtubeFollowing
        careerStatus
        hometown
        hometownState
        hometownCountry
        hometownLatitude
        hometownLongitude
        city
        country
        state
        longitude
        latitude
        favoriteNumber
        team {
          id
          name
          sport
        }
        league {
          id
          name
          sport
        }
        college {
          id
          name
        }
      }
      sports {
        id
        name
      }
    }
  }
`;
