import { forwardRef, useCallback } from 'react';
import cn from 'classnames';
import ReactSelect, {
  components,
  IndicatorProps,
  NamedProps,
  StylesConfig,
  OptionTypeBase,
  ValueContainerProps,
} from 'react-select';
import { isMobile } from 'react-device-detect';
import ReactAsyncSelect, { AsyncProps } from 'react-select/async';
import { AsyncPaginate } from 'react-select-async-paginate';
// UI
import Icon, { IconNames } from 'ui3/MillionsIcon/MillionsIcon';
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
import Text from 'ui3/Text/Text';

import styles from './Select.module.scss';

export type InputSize = 'large' | 'medium' | 'small';

type BasicSelectProps = NamedProps<OptionTypeBase, boolean> &
  (
    | { type?: 'default'; loadOptions?: never; cacheUniqs?: never }
    | ({ type: 'async' } & AsyncProps<OptionTypeBase>)
    | ({ type: 'async-paginate' } & Record<string, unknown>)
  );

type SelectProps = {
  error?: string | boolean;
  label?: string;
  labelClassName?: string;
  disabled?: boolean;
  hint?: string;
  dropdownIndicator?: IconNames | null;
  dropdownIndicatorPosition?: 'left' | 'right';
  size?: InputSize;
} & BasicSelectProps;

const getControlBorderColor = (
  error: boolean,
  isFocused: boolean,
  disabled: boolean
): string => {
  if (error) {
    return '#f43f36';
  }

  if (isFocused) {
    return '#525254';
  }

  if (disabled) {
    return '#666666';
  }

  return '#27282d';
};

const defaultFontStyles: React.CSSProperties = {
  fontFamily: 'GT America',
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 400,
};

const getCustomStyles = (
  disabled: boolean,
  error: boolean,
  size: SelectProps['size'],
  dropdownIndicatorPosition: SelectProps['dropdownIndicatorPosition']
): StylesConfig<OptionTypeBase, boolean> => {
  const defaultPadding =
    size === 'small' ? '4px 8px' : size === 'medium' ? '5px 12px' : '9px 12px';
  return {
    container: (provided) => ({
      ...provided,
      ...defaultFontStyles,
      minWidth: 130,
      width: '100%',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 1001,
      backgroundColor: '#141519',
      border: '1px solid #27282D',
      borderRadius: 10,
      boxShadow: 'none',
      overflow: 'hidden',
    }),
    option: (provided, { data: { disabled: isOptionDisabled } }) => {
      return {
        ...provided,
        ...defaultFontStyles,
        margin: 0,
        padding: defaultPadding,
        backgroundColor: isOptionDisabled ? '#666666' : '#141519',
        textAlign: 'left',
        color: isOptionDisabled ? '#d9d9d9' : '#ffffff',
        borderRadius: 0,
        '&:hover': {
          cursor: isOptionDisabled ? 'not-allowed' : 'pointer',
          backgroundColor: isOptionDisabled ? '#666666' : '#202127',
        },
      };
    },
    singleValue: (provided) => ({
      ...provided,
      ...defaultFontStyles,
      margin: 0,
      color: disabled ? '#666666' : '#ffffff',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      margin: 'auto',
      padding: 0,
      backgroundColor: disabled ? '#666666' : '#141519',
      justifyContent: 'flex-end',
      svg: {
        color: '#999999',
        fontSize: 24,
      },
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    input: (provided) => ({
      ...provided,
      ...defaultFontStyles,
      color: '#ffffff',
      margin: 0,
      // margin-left is not working without kebab case, but camelCase doesn't apply attribute to the element
      ...(dropdownIndicatorPosition === 'left' && { margin: '0 0 0 8px' }),
      input: {
        ...defaultFontStyles,
      },
    }),
    valueContainer: (provided) => {
      return {
        ...provided,
        ...defaultFontStyles,
        padding: defaultPadding,
      };
    },
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      borderRadius: 10,
    }),
    control: (provided, { isFocused }) => ({
      ...provided,
      minHeight: 'unset',
      boxSizing: 'border-box',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: getControlBorderColor(error, isFocused, disabled),
      borderRadius: 5,
      backgroundColor: disabled ? '#202127' : '#141519',
      boxShadow: 'none',
      overflow: 'hidden',
      ':hover': {
        cursor: 'pointer',
        borderColor: '#525254',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      ...defaultFontStyles,
      margin: 0,
      color: '#999999',
      // margin-left is not working without kebab case, but camelCase doesn't apply attribute to the element
      ...(dropdownIndicatorPosition === 'left' && { margin: '0 0 0 32px' }),
    }),
  };
};

const Select = forwardRef(
  (
    {
      type = 'default',
      error,
      label,
      labelClassName,
      hint,
      className,
      inputId,
      dropdownIndicator,
      dropdownIndicatorPosition = 'right',
      size = 'large',
      components: customComponents,
      styles: customStyles,
      ...rest
    }: SelectProps,
    ref
  ) => {
    const isDefaultType = type === 'default';
    const isAsyncType = type === 'async';
    const isAsyncPaginateType = type === 'async-paginate';
    const isError = Boolean(error);
    const isErrorLabelVisible = typeof error === 'string' && !!error.length;
    const isDisabled = !!rest.disabled || !!rest.isDisabled;

    const DropdownIndicator = useCallback(
      (props: IndicatorProps<OptionTypeBase, boolean>) => {
        if (
          dropdownIndicator === null ||
          dropdownIndicatorPosition === 'left'
        ) {
          return null;
        }

        return (
          <>
            <components.DropdownIndicator {...props}>
              <Icon name={dropdownIndicator || 'chevronDown'} />
            </components.DropdownIndicator>
          </>
        );
      },
      [dropdownIndicator, dropdownIndicatorPosition]
    );

    const ValueContainer = useCallback(
      (props: ValueContainerProps<OptionTypeBase, boolean>) => {
        const { children } = props;
        return (
          <components.ValueContainer {...props}>
            {dropdownIndicatorPosition === 'left' && (
              <Icon
                name={dropdownIndicator || 'chevronDown'}
                className={styles.leftIcon}
              />
            )}
            {children}
          </components.ValueContainer>
        );
      },
      [dropdownIndicatorPosition, dropdownIndicator]
    );

    const commonProps = {
      inputId,
      isDisabled,
      styles: {
        ...getCustomStyles(
          isDisabled,
          isError,
          size,
          dropdownIndicatorPosition
        ),
        ...customStyles,
      },
      isOptionDisabled: (option: any) => !!option.disabled,
      components: { ValueContainer, DropdownIndicator, ...customComponents },
      inputRef: ref,
      menuShouldBlockScroll: isMobile,
      captureMenuScroll: false,
      ...rest,
    };

    return (
      <div className={cn(styles.root, className)}>
        {label && (
          <label htmlFor={inputId} className={cn(styles.label, labelClassName)}>
            {label}
          </label>
        )}

        {isDefaultType && <ReactSelect {...commonProps} />}

        {isAsyncType && <ReactAsyncSelect {...(commonProps as any)} />}

        {isAsyncPaginateType && <AsyncPaginate {...commonProps} />}

        {isErrorLabelVisible && (
          <Text variant="captionRegular" color="error-default">
            {error}
          </Text>
        )}

        {hint && !isErrorLabelVisible && (
          <span className={styles.hint}>
            <MillionsIcon name="infoCircle" size={16} />
            <Text variant="captionRegular" color="lights-low">
              {hint}
            </Text>
          </span>
        )}
      </div>
    );
  }
);

Select.displayName = 'Select';

export default Select;
