import cn from 'classnames';
// Types
import { DesignTypes_designTypes } from 'api/designLab/types/DesignTypes';
import { DLStateValues } from 'components/DesignLab/DLConstructorMerch/DLConstructor';
// Ui
import Text from 'ui3/Text/Text';
// Components
import SplitLeftRightView from 'components/common3/SplitLeftRightView/SplitLeftRightView';
import StepChip from 'components/common3/StepChip/StepChip';
// Styles
import styles from './TypesOfLogoForm.module.scss';

type TypesOfLogoFormProps = {
  onChange: (values: DLStateValues) => void;
  options: DesignTypes_designTypes[];
  selectedValues: number[];
  step?: number;
};

const TypesOfLogoForm = ({
  options,
  selectedValues,
  step,
  onChange,
}: TypesOfLogoFormProps): JSX.Element => {
  const isCheckedMoreThanTree = selectedValues.length >= 3;

  const handleCheckboxChange = (id: number) => {
    const updated = selectedValues.some((item) => item === id)
      ? selectedValues.filter((item) => item !== id)
      : selectedValues.concat(id);

    onChange({
      typesOfLogo: updated,
    });
  };

  return (
    <SplitLeftRightView
      left={
        <div className={styles.left}>
          {step && <StepChip step={step} />}

          <Text variant="h2">What Type Of Logo Are You Looking For?</Text>

          <Text variant="body1Regular16">
            Help our design team understand the style you&apos;re looking for.
          </Text>
        </div>
      }
      right={
        <form className={styles.form}>
          <div className={styles.list}>
            {options.map((item) => {
              const isSelected = selectedValues.some((val) => val === item.id);
              const isAvailable = !isCheckedMoreThanTree || isSelected;

              const handleChange = () => {
                if (isAvailable) {
                  handleCheckboxChange(item.id);
                }
              };

              return (
                <button
                  type="button"
                  key={`${item.name}-${item.id}`}
                  className={cn(styles.button, {
                    [styles.disabled]: !isAvailable,
                    [styles.selected]: isSelected,
                  })}
                  onClick={handleChange}
                >
                  <img
                    src={item?.posterImage || ''}
                    alt={item?.description || ''}
                    className={styles.cardImage}
                  />

                  <Text variant="subtitle2">{item.name}</Text>
                  {item?.description && (
                    <Text variant="body3Regular" color="lights-medium">
                      {item.description}
                    </Text>
                  )}
                </button>
              );
            })}
          </div>
        </form>
      }
    />
  );
};

export default TypesOfLogoForm;
