// Types
import { DLStateValues } from 'components/DesignLab/DLConstructorMerch/DLConstructor';
// Ui
import Text from 'ui3/Text/Text';
// Components
import StyledSliderList from 'components/DesignLab/DLConstructorMerch/DetailsForm/StyledSliderList/StyledSliderList';
import SplitLeftRightView from 'components/common3/SplitLeftRightView/SplitLeftRightView';
import StepChip from 'components/common3/StepChip/StepChip';
// Styles
import styles from './DetailsForm.module.scss';

type DetailsFormProps = {
  onChange: (values: DLStateValues) => void;
  selectedValues: DLStateValues['details'];
  step?: number;
};

const DetailsForm = ({
  selectedValues,
  onChange,
  step,
}: DetailsFormProps): JSX.Element => {
  return (
    <SplitLeftRightView
      left={
        <div className={styles.left}>
          {step && <StepChip step={step} />}

          <Text variant="h2">Start Making Your New Merch Today!</Text>

          <Text variant="body1Regular16">
            At MILLIONS we can help you design new logos and merch. Have your
            own already? Upload to get started with your shop.
          </Text>
        </div>
      }
      right={
        <form className={styles.form}>
          <StyledSliderList
            selectedValues={selectedValues}
            onChange={onChange}
          />
        </form>
      }
    />
  );
};

export default DetailsForm;
