import Slider, { SliderProps } from 'rc-slider';
// Styles
import styles from './StyledSlider.module.scss';
import 'rc-slider/assets/index.css';

const StyledSlider = ({
  name,
  value,
  onChange,
  marks,
}: {
  name: string;
  value?: number | null;
  onChange: (value: number, name: string) => void;
  marks: SliderProps['marks'];
}) => {
  return (
    <div className={styles.slider}>
      <Slider
        trackStyle={{
          background: '#202127',
          borderRadius: '20px',
          height: 20,
        }}
        dotStyle={{ display: 'none' }}
        handleStyle={{
          height: 28,
          width: 28,
          marginTop: -4,
          position: 'relative',
          border: 'none',
          boxShadow: 'none',
        }}
        railStyle={{
          background: '#202127',
          borderRadius: '20px',
          height: 20,
        }}
        value={value || 3}
        onChange={(value) => onChange(value, name)}
        marks={marks}
        min={1}
        max={5}
      />
    </div>
  );
};

export default StyledSlider;
