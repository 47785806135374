// Types
import {
  DesignRequestStatus,
  DesignRequestType,
  MerchType,
} from 'api/graphql-global-types';

export const getDesignRequestType = (item: DesignRequestType): string => {
  switch (item) {
    case DesignRequestType.LogoAudit:
      return 'Logo audit';

    case DesignRequestType.LogoCreation:
      return 'Logo creation';

    case DesignRequestType.MerchCreation:
      return 'Merch creation';

    default:
      return '';
  }
};

export const getStatusString = (status: DesignRequestStatus): string => {
  switch (status) {
    case DesignRequestStatus.Accepted:
      return 'Accepted';

    case DesignRequestStatus.AutoAccepted:
      return 'Auto-Accepted';

    case DesignRequestStatus.AwaitingApproval:
      return 'Awaiting approval';

    case DesignRequestStatus.InProgress:
      return 'In progress';

    case DesignRequestStatus.OnReview:
      return 'On review';

    case DesignRequestStatus.Rejected:
      return 'Rejected';

    case DesignRequestStatus.Todo:
      return 'To do';

    default:
      return '';
  }
};

export type LocalStatus = null | 'approved' | 'rejected';

export const getLocalStatusName = (localStatus: LocalStatus): string => {
  switch (localStatus) {
    case 'approved':
      return '(Approved)';

    case 'rejected':
      return '(Rejected)';

    default:
      return '';
  }
};

export const getInitialLocalStatus = (
  status: DesignRequestStatus
): LocalStatus => {
  switch (status) {
    case DesignRequestStatus.Accepted:
      return 'approved';

    case DesignRequestStatus.AutoAccepted:
      return 'approved';

    case DesignRequestStatus.Rejected:
      return 'rejected';

    default:
      return null;
  }
};

export const marks = (from: string, to: string) => {
  const commonStyle = {
    top: '-40px',
    transform: 'none',
    fontFamily: '"GT America"',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
    color: '#fff',
  };

  return {
    1: {
      style: {
        ...commonStyle,
      },
      label: from,
    },
    5: {
      style: {
        left: 'auto',
        right: 0,
        ...commonStyle,
      },
      label: to,
    },
  };
};

export const designMerchTypeOptions = [
  {
    label: 'Hat',
    value: MerchType.Hat,
  },
  {
    label: 'Joggers',
    value: MerchType.Joggers,
  },
  {
    label: 'Hoodie',
    value: MerchType.Hoodie,
  },
  {
    label: 'Rash guard',
    value: MerchType.RashGuard,
  },
  {
    label: 'T-Shirt',
    value: MerchType.TShirt,
  },
];
