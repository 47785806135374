import { gql } from '@apollo/client';

export const UserBaseFields = gql`
  fragment UserBaseFields on User {
    id
    role
    lastName
    firstName
    slug
    email
  }
`;

export const UserFields = gql`
  fragment UserFields on User {
    id
    role
    subtype
    lastName
    firstName
    slug
    email
    phoneNumber
    isOnboarded
    isEmailSet
    onboardingSteps
    cartSize
    status
    hasPassword
    phoneOptIn
    hashtags {
      id
      name
    }
    avatarUrl
    avatarKey
    storeDetails {
      id
      athleticLevel
      hasActedOnAutoGeneratedMerch
      storeName
      storeTitle
      bio
      careerStatus
      avatarURL
      socialMediaLink
      description
      coverURL
      facebookLink
      twitterLink
      instagramLink
      tiktokLink
      youtubeLink
      facebookFollowing
      twitterFollowing
      tiktokFollowing
      instagramFollowing
      youtubeFollowing
      birthDate
      gender
      hometown
      hometownState
      hometownCountry
      hometownLongitude
      hometownLatitude
      state
      country
      city
      latitude
      longitude
      signUpFlow
      gymRole
    }
    sports {
      id
      name
    }
    brandDealSettings {
      id
      contactEmail
      minFlatPrice
    }
  }
`;

export const AuthorizedUserFields = gql`
  fragment AuthorizedUserFields on AuthorizedUser {
    id
    email
    accessToken
    refreshToken
    role
  }
`;

export const SocialSignUpResponseFields = gql`
  fragment SocialSignUpResponseFields on SocialSignUpResponse {
    socialSession {
      id
      firstName
      lastName
      email
      gender
      birthDate
      name
      provider
      socialAssets {
        id
        link
        name
        followersCount
      }
      sub
    }
  }
`;

export const upcomingParticipatedStreamFields = gql`
  fragment upcomingParticipatedStreamFields on User {
    upcomingParticipatedStream {
      id
      name
      slug
      imageURL
      store {
        id
        slug
      }
    }
  }
`;
