// Styles
import Text from 'ui3/Text/Text';
import styles from './StepChip.module.scss';

export type StepChipProps = {
  step: number;
};

const StepChip = ({ step }: StepChipProps): JSX.Element => {
  return (
    <div className={styles.root}>
      <Text variant="smallLabelBold" color="background-dim" uppercase>
        Step {step}
      </Text>
    </div>
  );
};

export default StepChip;
