import cn from 'classnames';
// Types
import { DesignTypes_designTypes_designSamples } from 'api/designLab/types/DesignTypes';
import { DLStateValues } from 'components/DesignLab/DLConstructorMerch/DLConstructor';
// Ui
import Text from 'ui3/Text/Text';
// Components
import SplitLeftRightView from 'components/common3/SplitLeftRightView/SplitLeftRightView';
import StepChip from 'components/common3/StepChip/StepChip';
// Styles
import styles from './PreferredDesignsForm.module.scss';

type PreferredDesignsFormProps = {
  onChange: (values: DLStateValues) => void;
  options: DesignTypes_designTypes_designSamples[];
  selectedValues: number[];
  step?: number;
};

const PreferredDesignsForm = ({
  onChange,
  options,
  selectedValues,
  step,
}: PreferredDesignsFormProps): JSX.Element => {
  const isCheckedMoreThanFive = selectedValues.length >= 5;

  const handleCheckboxChange = (id: number) => {
    const updated = selectedValues.some((item) => item === id)
      ? selectedValues.filter((item) => item !== id)
      : selectedValues.concat(id);

    onChange({
      designs: updated,
    });
  };

  return (
    <SplitLeftRightView
      left={
        <div className={styles.left}>
          {step && <StepChip step={step} />}

          <Text variant="h2">Which Design Examples Do You Like?</Text>

          <Text variant="body1Regular16">
            Pick up to 5 examples that match your style for a merch design
          </Text>
        </div>
      }
      right={
        <form className={styles.form}>
          <div className={styles.list}>
            {options.map((item) => {
              const isSelected = selectedValues.some((val) => val === item.id);
              const isAvailable = !isCheckedMoreThanFive || isSelected;

              const handleChange = () => {
                if (isAvailable) {
                  handleCheckboxChange(item.id);
                }
              };

              return (
                <button
                  type="button"
                  key={`${item.name}-${item.id}`}
                  className={cn(styles.button, {
                    [styles.disabled]: !isAvailable,
                    [styles.selected]: isSelected,
                  })}
                  onClick={handleChange}
                >
                  <img src={item.posterImage || ''} alt="Logo Example" />
                </button>
              );
            })}
          </div>
        </form>
      }
    />
  );
};

export default PreferredDesignsForm;
