import { useEffect, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
// Helpers
import { designMerchTypeOptions } from 'helpers/design-lab';
// Types
import { MerchType } from 'api/graphql-global-types';
// Api
import { DLStateValues } from 'components/DesignLab/DLConstructorMerch/DLConstructor';
// Components
import { showToast } from 'components/common/Toast/Toast';
import DLUploadedLogo from 'components/DesignLab/components/DLUploadedLogo/DLUploadedLogo';
import SplitLeftRightView from 'components/common3/SplitLeftRightView/SplitLeftRightView';
import StepChip from 'components/common3/StepChip/StepChip';
// Ui
import Text from 'ui3/Text/Text';
import Input from 'ui3/Input/Input';
import Select from 'ui3/Select/Select';
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
// Styles
import styles from './ExtraInformationForm.module.scss';

type ExtraInformationFormProps = {
  onChange: (values: DLStateValues) => void;
  selectedValues: DLStateValues['extra'];
  editMode: boolean;
  isLogoDesignRequest: boolean;
  step?: number;
};

const MAX_FILES = 10;

const ExtraInformationForm = ({
  onChange,
  selectedValues,
  editMode,
  isLogoDesignRequest,
  step,
}: ExtraInformationFormProps): JSX.Element => {
  const watchExamples = selectedValues?.storeExampleImages;
  const watchLogos = selectedValues?.storeLogos;
  const [selectedTypes, setSelectedTypes] = useState(
    selectedValues?.merchTypes || []
  );

  useEffect(() => {
    watchExamples?.forEach((file) => {
      if (typeof file !== 'string') {
        if (file.preview) {
          URL.revokeObjectURL(file.preview);
        }
      }
    });
  }, [watchExamples]);

  useEffect(() => {
    watchLogos?.forEach((file) => {
      if (typeof file !== 'string') {
        if (file.preview) {
          URL.revokeObjectURL(file.preview);
        }
      }
    });
  }, [watchLogos]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length <= MAX_FILES) {
        const filesWithPreview = acceptedFiles.map((item: File) =>
          Object.assign(item, {
            preview: URL.createObjectURL(item),
          })
        );
        if (isLogoDesignRequest) {
          onChange({
            extra: {
              ...selectedValues,
              storeLogos: filesWithPreview,
            },
          });
        } else {
          onChange({
            extra: {
              ...selectedValues,
              storeExampleImages: filesWithPreview,
            },
          });
        }
      } else {
        showToast({
          message: `${MAX_FILES} files maximum for upload. You are trying to upload ${acceptedFiles.length} files`,
          type: 'info',
        });
      }
    },
    [isLogoDesignRequest, onChange, selectedValues]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['image/png', 'image/jpeg', 'application/pdf'],
  });

  const handlePreviewImageRemove = (preview: string) => {
    onChange({
      extra: {
        ...selectedValues,
        storeExampleImages: selectedValues?.storeExampleImages?.filter(
          (item) => {
            if (typeof item === 'string') {
              return item !== preview;
            } else {
              return item.preview !== preview;
            }
          }
        ),
      },
    });
  };

  const handlePreviewLogoImageRemove = (preview: string) => {
    onChange({
      extra: {
        ...selectedValues,
        storeLogos: selectedValues?.storeLogos?.filter((item) => {
          if (typeof item === 'string') {
            return item !== preview;
          } else {
            return item.preview !== preview;
          }
        }),
      },
    });
  };

  const handleInputChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      extra: {
        ...selectedValues,
        [target.name]: target.value,
      },
    });
  };

  const handleSelect = (value: MerchType[]) => {
    onChange({
      extra: {
        ...selectedValues,
        merchTypes: value,
      },
    });
  };

  return (
    <SplitLeftRightView
      left={
        <div className={styles.left}>
          {step && <StepChip step={step} />}

          <Text variant="h2">Final Design Details</Text>

          <Text variant="body1Regular16">
            Almost done, just a few final details.
          </Text>
        </div>
      }
      right={
        <form className={styles.form}>
          {!isLogoDesignRequest && (
            <Input
              id="name"
              name="name"
              placeholder="Type here"
              label="What Would You Like Your Logo Or Merch To Say?"
              hint="Ex. name, nickname, company name, event name and details, a catchphrase or common saying etc."
              fieldSize="medium"
              maxLength={256}
              value={selectedValues?.name}
              onChange={handleInputChange}
            />
          )}

          <Input
            id="extraInfo"
            name="extraInfo"
            label="Any Other Details You Want To Include For Your MILLIONS Designer?"
            placeholder="Type here"
            hint="Feel free to comment anything additional you wish to have included in your logo that we didn't cover"
            fieldSize="medium"
            maxLength={1000}
            value={selectedValues?.extraInfo}
            onChange={handleInputChange}
          />

          <Select
            label="What Type Of Merch Would You Like?"
            placeholder="Select type"
            name="merchTypes"
            isMulti
            isDisabled={editMode}
            value={selectedTypes}
            options={designMerchTypeOptions}
            onChange={(types) => {
              const selectedTypes = types?.map((type) => ({
                value: type.value,
                label: type.label,
              }));
              setSelectedTypes(selectedTypes);
              handleSelect(selectedTypes.map((type) => type.value));
            }}
          />

          <Input
            id="socialMediaURL"
            name="socialMediaURL"
            label="Please Enter Your Social Media (Optional)"
            placeholder=""
            hint="We will use this link to personalize your design"
            fieldSize="medium"
            maxLength={1000}
            value={selectedValues?.socialMediaURL}
            onChange={handleInputChange}
          />

          <div>
            <label htmlFor="examples" className={styles.label}>
              {isLogoDesignRequest ? 'Attach Logo' : 'Attach Example'}
            </label>

            <p className={styles.description}>
              {isLogoDesignRequest
                ? 'Screenshots of logo you like. Attach here for our designers to work off of'
                : 'Screenshots of designs you like, your old logo, your signature, etc. Attach here for our designers to work off of'}
            </p>

            {isLogoDesignRequest && (
              <div className={styles.inputWrapper}>
                {watchLogos?.length ? (
                  <div className={styles.previewImagesList}>
                    {watchLogos.map((item) => {
                      if (typeof item === 'string') {
                        return (
                          <DLUploadedLogo
                            key={item}
                            onRemove={handlePreviewLogoImageRemove}
                            imageUrl={item}
                            alt="Store logo"
                          />
                        );
                      }

                      return (
                        <DLUploadedLogo
                          key={item.preview}
                          onRemove={handlePreviewLogoImageRemove}
                          imageUrl={item.preview || ''}
                          alt="Store logo"
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className={styles.uploadWrapper}>
                      <MillionsIcon name="cloudUpload" size={48} />
                      <div>
                        <Text variant="subtitle2">Upload Images</Text>
                        <Text variant="body3Regular" color="lights-low">
                          Image must be max. 5mb in JPEG od PNG format.
                        </Text>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {!isLogoDesignRequest && (
              <div className={styles.inputWrapper}>
                {watchExamples?.length ? (
                  <div className={styles.previewImagesList}>
                    {watchExamples.map((item) => {
                      if (typeof item === 'string') {
                        return (
                          <DLUploadedLogo
                            key={item}
                            onRemove={handlePreviewImageRemove}
                            imageUrl={item}
                            alt="Attach example"
                          />
                        );
                      }

                      return (
                        <DLUploadedLogo
                          key={item.preview}
                          onRemove={handlePreviewImageRemove}
                          imageUrl={item.preview || ''}
                          alt="Attach example"
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className={styles.uploadWrapper}>
                      <MillionsIcon name="cloudUpload" size={48} />
                      <div>
                        <Text variant="subtitle2">Upload Images</Text>
                        <Text variant="body3Regular" color="lights-low">
                          Image must be max. 5mb in JPEG od PNG format.
                        </Text>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </form>
      }
    />
  );
};

export default ExtraInformationForm;
