import cn from 'classnames';
// Ui
import Icon from 'ui/Icon/Icon';

import styles from './DLUploadedLogo.module.scss';

type DLUploadedLogoProps = {
  onRemove: (imageUrl: string) => void;
  imageUrl: string;
  className?: string;
  size?: 'small' | 'medium';
  alt?: string;
};

const DLUploadedLogo = ({
  onRemove,
  imageUrl,
  className,
  size = 'small',
  alt,
}: DLUploadedLogoProps): JSX.Element => {
  const handleRemoveButtonClick = () => {
    onRemove(imageUrl);
  };

  return (
    <div
      className={cn(
        styles.root,
        {
          [styles[`size-${size}`]]: size,
        },
        className
      )}
    >
      <img src={imageUrl} alt={alt} className={styles.image} />
      <button onClick={handleRemoveButtonClick} className={styles.removeButton}>
        <Icon name="close" />
      </button>
    </div>
  );
};

export default DLUploadedLogo;
