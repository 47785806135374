// Helpers
import { marks } from 'helpers/design-lab';
// Components
import { DLStateValues } from '../../DLConstructor';
import StyledSlider from '../StyledSlider/StyledSlider';

type StyledSliderListProps = {
  onChange: (values: DLStateValues) => void;
  selectedValues: DLStateValues['details'];
};

const StyledSliderList = ({
  selectedValues,
  onChange,
}: StyledSliderListProps): JSX.Element => {
  const handleSliderChange = (value: number, name: string) => {
    onChange({
      details: {
        ...selectedValues,
        [name]: value,
      },
    });
  };

  return (
    <>
      <StyledSlider
        name="playfulSophisticated"
        value={selectedValues?.playfulSophisticated}
        onChange={handleSliderChange}
        marks={marks('Playful', 'Sophisticated')}
      />
      <StyledSlider
        name="matureYouthful"
        value={selectedValues?.matureYouthful}
        onChange={handleSliderChange}
        marks={marks('Mature', 'Youthful')}
      />
      <StyledSlider
        name="classicModern"
        value={selectedValues?.classicModern}
        onChange={handleSliderChange}
        marks={marks('Classic', 'Modern')}
      />
      <StyledSlider
        name="feminineMasculine"
        value={selectedValues?.feminineMasculine}
        onChange={handleSliderChange}
        marks={marks('Feminine', 'Masculine')}
      />
      <StyledSlider
        name="geometricOrganic"
        value={selectedValues?.geometricOrganic}
        onChange={handleSliderChange}
        marks={marks('Geometric', 'Organic')}
      />
      <StyledSlider
        name="abstractLiteral"
        value={selectedValues?.abstractLiteral}
        onChange={handleSliderChange}
        marks={marks('Abstract', 'Literal')}
      />
      <StyledSlider
        name="economicalLuxurious"
        value={selectedValues?.economicalLuxurious || 3}
        onChange={handleSliderChange}
        marks={marks('Economical', 'Luxurious')}
      />
    </>
  );
};

export default StyledSliderList;
