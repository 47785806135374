import { gql } from '@apollo/client';

export const DESIGN_REQUESTS = gql`
  query DesignRequests($input: DesignRequestsFilterInput) {
    designRequests(input: $input) {
      entities {
        id
        type
        status
        storeId
        dueDate
        escalatedToId
        assignDate
        auditLogs {
          id
          createdAt
        }
        socialMediaPackImages {
          imageFileKey
          label
          socialMediaPackImageUrl
        }
        designCreationDetails {
          designCreationDetailsImages {
            id
            imageFileURL
          }
          designColorPalettes {
            id
            name
            posterImage
          }
          id
          storeExampleImages {
            id
            key
            url
          }
          socialMediaURL
          storeLogos {
            id
            key
            url
          }
          designName
          merchProductId
          storeLogos {
            id
            key
            url
          }
          merchItems {
            designRequestId
            merchId
            merch {
              id
              description
              type
              merchProductVariants {
                id
                merchProductId
                size
                color
                colorCode
                colorCode2
                gender
                price
                mockupImageFileKey
                mainMockupUrl
                printfulVariantId
                mockupImageURL
                mockupImages {
                  id
                  merchProductId
                  mockupImageURL
                  placement
                }
                customImages {
                  id
                  customMerchImageURL
                  imageFileKey
                  isMainImage
                }
              }
              mainImageUrl
              requestedProfit
              slug
              status
              storeId
              style
              title
              autoSocialMediaPackStatus
              minPrice
              autoSocialPackImages {
                id
                status
                url
              }
            }
          }
        }
      }
      total
      offset
      limit
    }
  }
`;

export const DESIGN_REQUEST = gql`
  query GetMerchDesign($id: String!) {
    getMerchDesign(id: $id) {
      id
      type
      status
      designCreationDetails {
        designCreationDetailsImages {
          id
          imageFileKey
          imageFileURL
        }
        storeExampleImages {
          id
          key
          url
        }
        storeLogos {
          id
          key
          url
        }
        id
        merchItems {
          designRequestId
          merchId
          merch {
            id
            description
            printfulProductId
            merchProductVariants {
              id
              merchProductId
              size
              color
              colorCode
              colorCode2
              price
              mockupImageFileKey
              printfulVariantId
              mockupImageURL
              mockupImages {
                id
                merchProductId
                mockupImageURL
                placement
              }
              customImages {
                id
                customMerchImageURL
                imageFileKey
                isMainImage
              }
            }
            productImageURL
            requestedProfit
            slug
            status
            storeId
            style
            title
          }
        }
        designName
        designCustomColor
        designExtraInfo
        socialMediaURL
        merchProductId
        designStyleSettings {
          id
          playfulSophisticated
          matureYouthful
          classicModern
          feminineMasculine
          geometricOrganic
          abstractLiteral
          economicalLuxurious
        }
        designTypes {
          id
          name
        }
        designSamples {
          id
          name
        }
        designColorPalettes {
          id
          name
        }
      }
      socialMediaPackImages {
        imageFileKey
        label
        socialMediaPackImageUrl
      }
    }
  }
`;

export const DESIGN_TYPES = gql`
  query DesignTypes {
    designTypes {
      id
      name
      posterImage
      description
      designSamples {
        id
        name
        posterImage
        designTypeId
      }
    }
  }
`;

export const DESIGN_COLOR_PALETTES = gql`
  query DesignColorPalettes {
    designColorPalettes {
      id
      name
      posterImage
    }
  }
`;
