import { useRef } from 'react';
import cn from 'classnames';
// Types
import { DesignColorPalettes_designColorPalettes } from 'api/designLab/types/DesignColorPalettes';
import { DLStateValues } from 'components/DesignLab/DLConstructorMerch/DLConstructor';
// Ui
import Text from 'ui3/Text/Text';
// Components
import SplitLeftRightView from 'components/common3/SplitLeftRightView/SplitLeftRightView';
import StepChip from 'components/common3/StepChip/StepChip';
// Styles
import styles from './PreferredColorsForm.module.scss';

type PreferredColorsFormProps = {
  onChange: (values: DLStateValues) => void;
  options: DesignColorPalettes_designColorPalettes[];
  selectedValues: number[];
  designCustomColor?: string;
  step?: number;
};

const PreferredColorsForm = ({
  onChange,
  options,
  selectedValues,
  designCustomColor,
  step,
}: PreferredColorsFormProps): JSX.Element => {
  const customColorRef = useRef<HTMLInputElement | null>(null);

  const checkedColorsCount = selectedValues.length;
  const isCheckedMoreThanThree =
    Number(Boolean(designCustomColor)) + checkedColorsCount >= 3;

  const handleColorCheckboxChange = (id: number) => {
    const updated = selectedValues.some((item) => item === id)
      ? selectedValues.filter((item) => item !== id)
      : selectedValues.concat(id);

    onChange({
      colors: updated,
    });
  };

  const handleCustomColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      designCustomColor: e.target.value,
    });
  };

  const handleCustomColorUncheck = () => {
    if (!designCustomColor) {
      return;
    }

    onChange({
      designCustomColor: '',
    });
  };

  const isAvailableCustomColor = !isCheckedMoreThanThree || designCustomColor;

  return (
    <SplitLeftRightView
      left={
        <div className={styles.left}>
          {step && <StepChip step={step} />}

          <Text variant="h2">Choose Colors For Your Logo</Text>

          <Text variant="body1Regular16">
            Pick up to 3 colors you’d like the designers to incorporate. Shades
            used may differ.
          </Text>
        </div>
      }
      right={
        <form className={styles.form}>
          <div className={styles.list}>
            {options.map((item) => {
              const isSelected = selectedValues.some((val) => val === item.id);
              const isAvailable = !isCheckedMoreThanThree || isSelected;

              const handleChange = () => {
                handleColorCheckboxChange(item.id);
              };

              return (
                <button
                  type="button"
                  key={`${item.name}`}
                  className={cn(styles.button, {
                    [styles.disabled]: !isAvailable,
                    [styles.selected]: isSelected,
                  })}
                  disabled={!isAvailable}
                  data-testid={`${item.name}`}
                  onClick={handleChange}
                >
                  <img
                    src={item.posterImage || ''}
                    alt="Logo Example"
                    className={styles.cardImage}
                  />

                  <Text variant="subtitle2">{item.name}</Text>
                </button>
              );
            })}
            <button
              type="button"
              className={styles.colorPickerButton}
              onClick={handleCustomColorUncheck}
            >
              <label
                htmlFor="customColorOption"
                className={cn(styles.card, {
                  [styles.disabled]: !isAvailableCustomColor,
                  [styles.selected]: !!designCustomColor,
                })}
                style={{
                  backgroundColor: designCustomColor,
                }}
                data-testid="customColorLabel"
              >
                <img
                  src={'/images/color_picker.png'}
                  alt="Logo Example"
                  className={styles.cardImage}
                />

                <Text variant="subtitle2">Customize Your Own Colors</Text>
                <input
                  type="color"
                  name="customColor"
                  className={cn(styles.colorPickerInput, {
                    [styles.hiddenColorPicker]:
                      designCustomColor || !isAvailableCustomColor,
                  })}
                  ref={customColorRef}
                  value={designCustomColor}
                  onChange={handleCustomColorChange}
                />
              </label>
            </button>
          </div>
        </form>
      }
    />
  );
};

export default PreferredColorsForm;
