/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames';
// Types
import { DesignRequestType } from 'api/graphql-global-types';
// Components
import { DLStateValues } from 'components/DesignLab/DLConstructorMerch/DLConstructor';
import SplitLeftRightView from 'components/common3/SplitLeftRightView/SplitLeftRightView';
import StepChip from 'components/common3/StepChip/StepChip';
// Styles
import styles from './TypesOfRequestForm.module.scss';
// UI
import Text from 'ui3/Text/Text';

type TypesOfRequestFormProps = {
  onChange: (values: DLStateValues) => void;
  selectedValue: string;
  step?: number;
};

const TypesOfRequestForm = ({
  selectedValue,
  onChange,
  step,
}: TypesOfRequestFormProps): JSX.Element => {
  const options = [
    {
      name: DesignRequestType.MerchCreation,
      description: 'Request new merch',
      image: '/images/request_design.png',
    },
    {
      name: DesignRequestType.MerchCreationWithLogo,
      description: 'Upload your own logo',
      image: '/images/upload_design.png',
    },
  ];

  const handleCheckboxChange = (itemName: string) => {
    onChange({
      designRequestType: itemName,
    });
  };

  return (
    <SplitLeftRightView
      left={
        <div className={styles.left}>
          {step && <StepChip step={step} />}

          <Text variant="h2">Start Making Your New Merch Today!</Text>

          <Text variant="body1Regular16">
            At MILLIONS we can help you design new logos and merch. Have your
            own already? Upload to get started with your shop.
          </Text>
        </div>
      }
      right={
        <form className={styles.form}>
          {options.map((item) => {
            const isSelected = selectedValue === item.name;

            const handleChange = () => {
              if (!isSelected) {
                handleCheckboxChange(item.name);
              }
            };

            return (
              <button
                type="button"
                key={item.name}
                onClick={handleChange}
                className={cn(styles.button, {
                  [styles.selected]: isSelected,
                })}
                aria-label={item.description}
              >
                <img src={item.image} alt={item.description} />
                <Text variant="h5"> {item.description}</Text>
              </button>
            );
          })}
        </form>
      }
    />
  );
};

export default TypesOfRequestForm;
